<template>
    <div class="logo-wrapper">
        <img src="@/assets/wis_logo.svg" alt="logo">
        <h1 class="title">Written in Stone</h1>
    </div>
</template>

<script>
export default {
  name: 'WisLogo',
}
</script>